import React, { Component } from "react";
import { HashRouter, Route, Link, NavLink } from "react-router-dom";
import Home from "./components/home";
import Toolkit from "./components/toolkit";
import Methods from "./components/methods";
import "./App.css";
import db from "./base";
import MethodDetails from "./components/methodDetails";

class App extends Component {
  constructor() {
    super();
    this.state = {
      data: {
        phases: {},
        methods: {}
      },
      getMethodDetailsData: null
    };
  }
  componentDidMount() {
    db.collection("phases")
      .get()
      .then((q) => {
        let p = q.docs.reduce((acc, d) => {
          acc[d.id] = { ...d.data() };
          return acc;
        }, {});
        this.setState({
          data: {
            ...this.state.data,
            phases: p
          }
        });
      });
    // .then(() => {
    //   this.setState({
    //     ...this.state,
    //     input: this.data.phases.RQ1.input,
    //     activePhase: this.data.phases.RQ1.input.activePhase
    //   });
    // });
    db.collection("methods")
      .get()
      .then((q) => {
        let m = q.docs.reduce((acc, d) => {
          acc[d.id] = { ...d.data() };
          return acc;
        }, {});
        this.setState({
          data: {
            ...this.state.data,
            methods: m
          },
          getMethodDetailsData: this.getMethodDetailsData
        });
      });
  }

  getMethodDetailsData = (name) => {
    return this.state.data.methods[name];
  };

  render() {
    return (
      <HashRouter>
        {/* IMPORTANT: It is not recommended using HashRouter in production, every web app should aspire to use browserHistory.
          It has disadvantages such as:
            Server-side rendering is not possible with this approach. As far as Search Engine Optimization (SEO) is concerned, your website consists of a single page with hardly any content on it.
            Ugly URL rendering
          Although, here it is used for quick prototyping without the need of a server to deploy it (a static file serving server will do.
          THIS SHOULD BE CHANGED TO <BrowserRouter basename={process.env.PUBLIC_URL}></BrowserRouter> ONCE CONSIDERD READY FOR 'REAL' PRODUCTION
        */}
        <React.Fragment>
          {/* todo: move in seperate components in layout folder */}
          <nav>
            <div className="nav navbar navbar-expand-md navbar-dark bg-dark">
              <Link to="/" className="navbar-brand">
                User Innovation Toolkit
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarsExampleDefault"
                aria-controls="navbarsExampleDefault"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarsExampleDefault">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <NavLink exact to="/" className="nav-link">
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/toolkit" className="nav-link">
                      Toolkit
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/methods" className="nav-link">
                      Methods
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className="main-container">
            <Route exact path="/" component={Home} />
            <Route
              exact
              path="/toolkit"
              render={() => <Toolkit data={this.state.data} />}
            />
            <Route
              exact
              path="/methods"
              render={() => <Methods methods={this.state.data.methods} />}
            />
            <Route
              path="/methods/:name"
              render={(props) => (
                <MethodDetails
                  {...props}
                  getMethodDetailsData={this.state.getMethodDetailsData}
                />
              )}
            />
          </div>
          <footer className="footer">
            <div className="d-flex justify-content-center">
              <span className="text-muted">
                Made by&nbsp;
                <a href="https://www.ugent.be/ps/communicatiewetenschappen/mict/en">
                  imec-mict-UGent
                </a>
              </span>
              -<span className="text-muted">User Innovation Toolkit</span>
            </div>
          </footer>
        </React.Fragment>
      </HashRouter>
    );
  }
}

export default App;
