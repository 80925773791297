import React, { Component } from "react";
import DOMPurify from "dompurify";
import { isEmptyObj } from "../helpers";
import Loading from "./layout/Loading";
import { pdfjs, Document, Page } from "react-pdf";
import "./methodDetails.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;

class MethodDetails extends Component {
  constructor(props) {
    super(props);
    if (this.dataInProps()) {
      this.name = this.props.keyName; //the key of the object
      this.state = {
        numPages: null,
        scale: 1.0,
        data: this.props.data
      };
    } else {
      this.name = this.props.match.params.name; //the key of the object
      this.state = {
        numPages: null,
        scale: 1.0,
        data: {}
      };
    }
  }

  componentDidMount() {
    if (!this.dataIsLoaded() && this.props.getMethodDetailsData !== null) {
      this.setState({
        data: this.props.getMethodDetailsData(this.name)
      });
    }
  }

  componentDidUpdate() {
    if (!this.dataIsLoaded() && this.props.getMethodDetailsData !== null) {
      this.setState({
        data: this.props.getMethodDetailsData(this.name)
      });
    }
  }

  dataIsLoaded = () => {
    return !isEmptyObj(this.state.data);
  };

  dataInProps = () => {
    return (
      typeof this.props.data !== "undefined" && !isEmptyObj(this.props.data)
    );
  };

  onPageLoad = (page) => {
    const parentDiv = document.querySelector("#cardPDF");
    let pageScale = parentDiv.clientWidth / page.originalWidth;
    if (this.state.scale !== pageScale) {
      this.setState({
        ...this.state,
        scale: pageScale
      });
    }
  };

  onDocumentLoadSuccess = (document) => {
    const { numPages } = document;
    this.setState({
      numPages
    });
  };

  renderContinueToToolkitButton = () => {
    return (
      <div className="d-flex justify-content-between my-3">
        <button
          type="button"
          className="btn btn-success btn-lg"
          onClick={this.props.continuePhase}>
          <i className="fas fa-check-circle" />
          &nbsp; Continue to complete the template
        </button>
      </div>
    );
  };

  renderCard = () => {
    const { numPages } = this.state;
    let pdf = null;
    if (this.state.data.isFromToolbox) {
      pdf = require(`./../cardset/${this.name}.pdf`);
    }

    return (
      <div id="cardPDF" className="col-md-5 offset-md-1 my-3">
        <Document file={pdf} onLoadSuccess={this.onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              onLoadSuccess={this.onPageLoad}
              scale={this.state.scale}
            />
          ))}
        </Document>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.dataIsLoaded() ? (
          <div className="container methodDetails">
            <div className="row">
              <div className="col-md-6 mt-3">
                <div>
                  <h1>{this.state.data.title}</h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(this.state.data.info)
                    }}
                  />
                  {this.props.continuePhase
                    ? this.renderContinueToToolkitButton()
                    : null}
                </div>
              </div>
              {this.state.data.isFromToolbox ? this.renderCard() : null}
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </React.Fragment>
    );
  }
}

export default MethodDetails;
