import React, { Component } from "react";

class BsStep extends Component {
  handleStepClick = () => {
    this.props.onStepClick(undefined, this.props.gotoKey, this.props.gotoType);
  };

  render() {
    return (
      <div className="step">
        <button
          type="button"
          className="btn btn-link step-trigger"
          onClick={this.handleStepClick}>
          <span className="bs-stepper-circle">{this.props.phaseNumber}</span>
          <span className="bs-stepper-label">{this.props.phaseName}</span>
        </button>
      </div>
    );
  }
}
export default BsStep;
