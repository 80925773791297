import firebase from "@firebase/app";
import "@firebase/firestore";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBzrMdC_8He4thA18uyOkGL5t1APdTKiVw",
  authDomain: "user-innovation-toolkit.firebaseapp.com",
  databaseURL: "https://user-innovation-toolkit.firebaseio.com",
  projectId: "user-innovation-toolkit",
  storageBucket: "user-innovation-toolkit.appspot.com",
  messagingSenderId: "359572167861"
});

const db = firebase.firestore();

export { firebaseApp };
export default db;
