import React, { Component } from "react";
import "./input-output-cards.css";
import DOMPurify from "dompurify";
import { pdfjs, Document, Page } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;

class RqOutput extends Component {
  state = {
    numPages: null
  };

  onDocumentLoadSuccess = (document) => {
    const { numPages } = document;
    this.setState({
      numPages
    });
  };

  render() {
    const { numPages } = this.state;
    const pdf = require(`./../templates-toolkit/${
      this.props.data.activePhase
    }.pdf`);
    return (
      <div className="row">
        <div className="col-md-12 mx-auto">
          <div className="card wizard-card">
            <div className="wizard-header">
              <h3 className="wizard-title">{this.props.data.title}</h3>
              <p className="category">{this.props.data.subtitle}</p>
            </div>
            <div className="wizard-content">
              <div className="d-flex flex-row justify-content-end">
                <div className="col-md-2">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={pdf}
                    role="button"
                    className="btn btn-light btn-sm ml-auto">
                    <i className="fa fa-download" /> Download Template
                  </a>
                </div>
              </div>

              <Document file={pdf} onLoadSuccess={this.onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(this.props.data.info)
                }}
              />
            </div>
            <div className="wizard-footer pt-3">
              <div className="text-center">
                <div className="ui-group-buttons">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.props.gotoRqIsUnanswered}>
                    <i className="fas fa-times-circle" />
                    &nbsp; No, the template is incomplete due to assumptions
                  </button>
                  <div className="or" />
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.props.gotoRqIsAnswered}>
                    <i className="fas fa-check-circle" />
                    &nbsp; Yes, the template is complete without assumptions
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RqOutput;
