import React, { Component } from "react";
import "./toolkit.css";
import RqInput from "./RQ-input";
import RqOutput from "./RQ-output";
import MethodDetails from "./methodDetails";
import Loading from "./layout/Loading";
import BsStepBar from "./layout/bs-stepsbar";
import { getPropByString, isEmptyObj } from "./../helpers";
// import data from "./../testData";

class Toolkit extends Component {
  constructor(props) {
    //todo: something with react router and the switch statement in App.js forces the toolkit component to be recreated every time it is called upon. This should be made into a singleton.
    super(props);
    if (
      !isEmptyObj(this.props.data.phases) &&
      !isEmptyObj(this.props.data.methods)
    ) {
      this.data = props.data;
      this.state = {
        input: this.data.phases.RQ1.input,
        activePhase: this.data.phases.RQ1.input.activePhase, //todo: don't store this in database but calculate this
        displayedComponent: "input", //default
        activeDataKey: "RQ1.input", //default
        output: {},
        methodDetails: {},
        dataIsLoaded: true
      };
    } else {
      this.state = {
        displayedComponent: "input",
        input: {},
        output: {},
        methodDetails: {},
        activePhase: "",
        activeDataKey: "",
        dataIsLoaded: false
      };
      this.data = {
        phases: {},
        methods: {}
      };
    }
  }

  componentDidUpdate() {
    if (!isEmptyObj(this.props.data.phases) && isEmptyObj(this.data.phases)) {
      //check if props are passed in from database load for the first time.
      this.data.phases = this.props.data.phases;
      this.setState({
        ...this.state,
        input: this.data.phases.RQ1.input,
        activePhase: this.data.phases.RQ1.input.activePhase,
        dataIsLoaded: this.dataIsLoaded()
      });
    }
    if (!isEmptyObj(this.props.data.methods) && isEmptyObj(this.data.methods)) {
      //check if props are passed in from database load for the first time.
      this.data.methods = this.props.data.methods;
      if (this.dataIsLoaded()) {
        this.setState({
          ...this.state,
          dataIsLoaded: true
        });
      }
    }
  }

  dataIsLoaded = () => {
    return !isEmptyObj(this.data.phases) && !isEmptyObj(this.data.methods);
  };

  getComponent = () => {
    let dataToRender = getPropByString(
      this.state,
      this.state.displayedComponent
    );
    return {
      input: (
        <RqInput
          data={dataToRender}
          gotoRqIsAnswered={this.gotoRqIsAnswered}
          gotoRqIsUnanswered={this.gotoRqIsUnanswered}
        />
      ),
      output: (
        <RqOutput
          data={dataToRender}
          gotoRqIsAnswered={this.gotoRqIsAnswered}
          gotoRqIsUnanswered={this.gotoRqIsUnanswered}
        />
      ),
      methodDetails: (
        <MethodDetails
          keyName={this.state.activeDataKey.split(".").pop()} //last part is the keyname (i.e. methods.affinitydiagramming)
          data={dataToRender}
          continuePhase={this.continuePhase}
        />
      )
    };
  };

  gotoRqIsAnswered = () => {
    this.goTo("RqIsAnsweredGoTo");
  };

  gotoRqIsUnanswered = () => {
    this.goTo("RqIsUnknownGoTo");
  };

  continuePhase = () => {
    this.goTo("continuePhase");
  };

  /**
   * This function is used to go to another state to show (this can be input, output or method). This function
   *
   * @param {string} propName : (i.e. "RqIsAnsweredGoTo") the property in the current rendered element where we can find the key and type that holds the next path to be rendered. Can be the only parameter, or can be left blank if @param gotoKey and @param gotoType are provided.
   * @param {string} gotoKey : the passed key that holds the path to the next data to be rendered.
   * @param {string} gotoType : the passed type of data in the path to be rendered. Required if gotoKey is Passed.
   * @memberof Toolkit
   */
  goTo = (propName, gotoKey, gotoType) => {
    let componentNowRendered = getPropByString(
      this.state,
      this.state.displayedComponent
    );

    if (!gotoKey) {
      //we need a propname if we don't get a key passed, so we can look up the key.
      if (!propName)
        throw new Error(
          "no prop passed to select which path to follow in the descision tree."
        );
      gotoKey = getPropByString(componentNowRendered, propName).key;
    } else if (!gotoType) {
      //key and type should always be together passsed
      throw new Error("key to render is passed but not which type it is.");
    }

    let componentToRender = getPropByString(this.data, gotoKey);
    let toRenderActivePhase = componentToRender.activePhase
      ? componentToRender.activePhase
      : this.state.activePhase;

    //1.Yes i.e. output element
    //2. No i.e. method element
    //3. Fixed passed element, (i.e. input)
    let commonStateProps = {
      activePhase: toRenderActivePhase,
      activeDataKey: gotoKey
    };
    if (propName) {
      this.setState({
        ...this.state,
        ...commonStateProps,
        displayedComponent: componentNowRendered[propName].type,
        [componentNowRendered[propName].type]: componentToRender
      });
    } else {
      this.setState({
        ...this.state,
        ...commonStateProps,
        displayedComponent: gotoType,
        [gotoType]: componentToRender
      });
    }
  };

  changeSteps = (componentNowRendered, componentToRender) => {
    let nowRenderedPhase = getPropByString(
      this.data,
      componentNowRendered.activePhase
    );
    let toRenderPhase = getPropByString(
      this.data,
      componentToRender.activePhase
    );

    if (nowRenderedPhase !== toRenderPhase) {
      this.stepper.to(toRenderPhase.phaseNumber);
    }
  };

  renderComponent = () => {
    return getPropByString(this.getComponent(), this.state.displayedComponent);
  };

  render() {
    return (
      <div id="toolkit" className="main-container-wrap bg-home">
        <main role="main" className="container w-100 h-100 pb-5">
          <div className="bs-stepper bgb">
            <div className="bs-stepper-header">
              {this.dataIsLoaded() ? (
                <BsStepBar
                  goTo={this.goTo}
                  phases={this.data.phases}
                  activePhase={this.state.activePhase}
                />
              ) : null}
            </div>
          </div>
          {this.dataIsLoaded() ? this.renderComponent() : <Loading />}
        </main>
      </div>
    );
  }
}

export default Toolkit;
