import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./home.css";
import "./layout/cards.css";

class Home extends Component {
  render() {
    return (
      <div id="home" className="main-container-wrap bg-home">
        <main role="main" className="container-flex pb-5">
          <div className="col-md-8 mx-auto text-center home-intro">
            <div>
              <h1 className="bgb">User Innovation Toolkit</h1>
            </div>
            <div>
              <p className="bgb">
                Do you want to start to innovate? Are you lost in the process
                and various methods? mict is here to help.
              </p>
            </div>
          </div>
          <div className="col-md-10 mx-auto">
            <div className="row custom-cards">
              <div className="col-sm-6 col-md-4 col-lg-3 offset-lg-3 offset-md-2">
                <div className="card">
                  {/* you could make this card component into a react component */}
                  <Link to="/toolkit">
                    <img
                      alt="toolkit"
                      className="card-img-top"
                      src={require("./../images/bg-toolkit.jpg")}
                    />
                    <div className="card-block">
                      <h4 className="card-title">Start to Innovate Toolkit</h4>
                      <div className="card-text">
                        The start to innovate toolkit <b>guides you</b> along
                        your innovation research using the Design Thinking
                        methodology.
                        <br />
                        <b>Templates</b> are provided to help you determine in
                        which design thinking fase you currently are, where you
                        should iterate to and which <b>assumptions</b> you made
                        an need to justify onwards.
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <div className="card">
                  {/* you could make this card component into a react component */}
                  <Link to="/methods">
                    <img
                      alt="methods"
                      className="card-img-top"
                      src={require("./../images/bg-methods.jpg")}
                    />
                    <div className="card-block">
                      <h4 className="card-title">User Innovation Methods</h4>
                      <div className="card-text">
                        A digital overview of the User Innovation Toolbox its
                        methods.
                        <br />
                        These methods are categorized in four islands:
                        opportunity, solution, business model &amp; usage, which
                        help you involve various <b>users and stakeholders</b>
                        &nbsp;in the&nbsp;
                        <b> identification, development and testing</b> of
                        innovative solutions.
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
export default Home;
