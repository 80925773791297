import React, { Component } from "react";
import { isEmptyObj, filterObject } from "./../helpers";
import Loading from "./layout/Loading";
import MethodThumbnail from "./methodThumbail";
import "./methods.css";
import "./layout/cards.css";

//bash scripts for images
// for file in ./*.pdf; do sips -s format jpeg $file -c 625 420 --out ./thumbs/${file%.*}.jpeg;done
// for file in ./*.jpeg; do convert $file -crop 420x265+0+268 ./../thumbs/${file%.*}.jpeg ;done

class Methods extends Component {
  constructor(props) {
    super(props);
    if (!isEmptyObj(this.props.methods)) {
      this.methods = this.filterOnlyToolboxMethods(this.props.methods);
      this.state = {
        dataIsLoaded: true
      };
    } else {
      this.methods = {};
      this.state = {
        dataIsLoaded: false
      };
    }
  }

  filterOnlyToolboxMethods = (methods) => {
    //for now, we only show toolbox methods on the method page because we only have visuals from these...
    return filterObject(methods, (method) => method.isFromToolbox === true);
  };

  componentDidUpdate() {
    if (!isEmptyObj(this.props.methods) && isEmptyObj(this.methods)) {
      this.methods = this.filterOnlyToolboxMethods(this.props.methods);
      this.setState({
        ...this.state, //not necessay with one attribute but make it future safe
        dataIsLoaded: true
      });
    }
  }

  dataIsLoaded = () => {
    return !isEmptyObj(this.methods);
  };

  getAllMethods = () => {
    return Object.entries(this.methods).map(([keyName, val]) => (
      <MethodThumbnail key={keyName} method={val} id={keyName} />
    ));
  };

  render() {
    return (
      <div id="methods" className="main-container-wrap bg-home">
        <main role="main" className="container-flex pb-5">
          <div className="col-md-8 mx-auto text-center methods-intro">
            <div>
              <h1 className="bgb">User Innovation Toolbox Methods</h1>
            </div>
            <div>
              <p className="bgb">
                This pages provides you with an overview of methods that can be
                used to inlove users and other stakeholders in different phases
                of the innovation research process.
              </p>
            </div>
          </div>
          <div className="container pt-5">
            <div className="row custom-cards">
              {this.dataIsLoaded() ? this.getAllMethods() : <Loading />}
            </div>
          </div>
        </main>
      </div>
    );
  }
}
export default Methods;
