import React from "react";
import { Link } from "react-router-dom";

class MethodThumbnail extends React.PureComponent {
  render() {
    return (
      <div className="col-sm-6 col-md-4 col-lg-3 pb-3">
        <div className="card">
          <Link to={`/methods/${this.props.id}`}>
            <img
              alt="toolkit"
              className="card-img-top"
              src={require(`./../images/thumbs/${this.props.id}.jpeg`)}
            />
            <div className="card-block">
              <h5 className="text-bold mb-0">{this.props.method.title}</h5>
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default MethodThumbnail;
