import React from "react";

const Loading = () => {
  return (
    <h1 className="d-inline-block mx-auto mt-5">
      <i className="fas fa-circle-notch fa-spin" />
      Loading data
    </h1>
  );
};

export default Loading;
