import React, { Component } from "react";
import "./input-output-cards.css";
import DOMPurify from "dompurify";

class RqInput extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-12 mx-auto">
          <div className="card wizard-card">
            <div className="wizard-header">
              <h3 className="wizard-title">{this.props.data.title}</h3>
              <p className="category">{this.props.data.shortExplenation}</p>
            </div>
            <div className="wizard-content">
              <div className="card">
                <div
                  className="card-header"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(this.props.data.researchQuestion)
                  }}
                />
                <div
                  className="card-body"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(this.props.data.info)
                  }}
                />
              </div>
            </div>
            <div className="wizard-footer pt-3">
              <div className="text-center">
                <div className="ui-group-buttons">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.props.gotoRqIsUnanswered}>
                    <i className="fas fa-times-circle" />
                    &nbsp; No, I only have assumptions to answer this question
                  </button>
                  <div className="or" />
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.props.gotoRqIsAnswered}>
                    <i className="fas fa-check-circle" />
                    &nbsp; Yes, I have the evidence to answer this question
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RqInput;
