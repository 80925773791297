import React, { Component } from "react";
import BsStep from "./bs-step";
import Stepper from "./bs-stepper";
import { getPropByString } from "./../../helpers";
import "./bs-stepper.min.css";

class BsStepBar extends Component {
  componentDidMount() {
    this.stepper = new Stepper(document.querySelector(".bs-stepper"), {
      linear: false,
      animation: true
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activePhase !== this.props.activePhase) {
      let phaseNumber = getPropByString(
        this.props.phases,
        this.props.activePhase
      ).phaseNumber;
      this.stepper.to(phaseNumber);
    }
  }

  render() {
    return Object.keys(this.props.phases).map((key, index) => {
      return (
        <React.Fragment key={this.props.phases[key].phaseNumber}>
          {index > 0 ? <div className="line" /> : null}
          <BsStep
            phaseName={this.props.phases[key].phaseName}
            phaseNumber={this.props.phases[key].phaseNumber}
            gotoType="input"
            gotoKey={`phases.${key}.input`}
            onStepClick={this.props.goTo}

            // i do this to avoid passing the whole input/output objects
          />
        </React.Fragment>
      );
    });
  }
}
export default BsStepBar;
